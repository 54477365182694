import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  differenceInCalendarDays,
  eachDay,
  getDayOfYear,
  isSameDay,
  parse,
} from 'date-fns';
import classnames from 'classnames';

import { init, logIn, loadEvents, loadCalendars } from './gcal';
import styles from './Calendar.module.css';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const getWeekIndex = date => {
  const firstDayOfWeek = new Date(date.getFullYear(), 0, 1).getDay();
  return Math.floor((getDayOfYear(date) - 1 + firstDayOfWeek) / 7) + 1;
};

const addEvents = (events, event, start, end, firstDayOfYear) => {
  const clipped =
    Math.ceil((start + firstDayOfYear) / 7) * 7 - firstDayOfYear + 1;
  const endSegment = Math.min(end, clipped);
  const event1 = {
    startDate: event.start.date,
    endDate: event.end.date,
    start,
    clipped,
    endSegment,
    end,
    title: event.summary,
    days: endSegment - start,
  };
  const event2 = { ...event1, days: end - endSegment };

  for (let i = start; i < endSegment; i++) {
    if (i === start) {
      addEvent(events, i, event1);
    } else if (i === endSegment) {
      addEvent(events, i, event2);
    } else {
      addEvent(events, i, null);
    }
  }
  if (end > endSegment) {
    addEvents(events, event, endSegment, end, firstDayOfYear);
  }
  return events;
};

const addEvent = (events, index, event) => {
  events[index] = events[index] || [];
  events[index].push(event);
};

const parseEvents = (events, firstDayOfYear) =>
  events.reduce((acc, event) => {
    const start = event.start.date && getDayOfYear(parse(event.start.date));
    const end = event.end.date && getDayOfYear(parse(event.end.date));
    if (!start) return acc;
    addEvents(acc, event, start, end, firstDayOfYear);
    return acc;
  }, []);

const Calendar = () => {
  const [calendars, setCalendars] = useState([]);
  const [calendarsToDisplay, setCalendarsToDisplay] = useState([]);
  const [events, setEvents] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const now = new Date();
  const year = now.getFullYear();
  const firstDayOfYear = new Date(year, 0, 1).getDay();
  console.log({ firstDayOfYear });

  const handleDisplayCalendarChange = useCallback(
    e => {
      const { checked, value } = e.target;
      const values = calendarsToDisplay.filter(cal => cal !== value);
      setCalendarsToDisplay(checked ? [...values, value] : values);
    },
    [calendarsToDisplay],
  );

  const dates = useMemo(() => {
    const firstOfYear = new Date(year, 0, 1);
    const lastOfYear = new Date(year, 11, 31);

    return eachDay(firstOfYear, lastOfYear).map((date, index) => ({
      date,
      month: date.getMonth() + 1,
      monthName: months[date.getMonth()],
      dayOfMonth: date.getDate(),
      dayOfWeek: date.getDay() + 1,
      dayOfYear: index + 1,
      isToday: isSameDay(date, now),
      week: getWeekIndex(date),
    }));
  }, [now, year]);

  useEffect(() => {
    init(loggedIn => {
      setLoggedIn(loggedIn);
    });
  }, []);

  useEffect(() => {
    if (loggedIn) {
      loadCalendars().then(response => {
        console.log('calendars', response);
        setCalendars(response.result.items);
      });
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn) return;
    let didCancel = false;

    const fetchEvents = async () => {
      const responses = await Promise.all(calendarsToDisplay.map(loadEvents));
      const evts = responses.reduce(
        (acc, response) => [...acc, ...response.result.items],
        [],
      );
      if (!didCancel) {
        setEvents(parseEvents(evts, firstDayOfYear));
      }
    };

    if (calendarsToDisplay.length) {
      fetchEvents();
    } else {
      setEvents([]);
    }

    return () => {
      didCancel = true;
    };
  }, [calendarsToDisplay, loggedIn, firstDayOfYear]);
  console.log({ dates, events });

  return (
    <div>
      <button onClick={logIn}>Log in</button>

      <details>
        <summary>Calendars</summary>
        {calendars.map(cal => (
          <label key={cal.id} className={styles.calendarCheckbox}>
            <input
              type="checkbox"
              value={cal.id}
              onChange={handleDisplayCalendarChange}
            />{' '}
            {cal.summary}
          </label>
        ))}
      </details>

      <h1 className={styles.year}>{year}</h1>

      <div className={styles.container}>
        <div className={styles.calendar}>
          <div className={styles.dayHeader}>S</div>
          <div className={styles.dayHeader}>M</div>
          <div className={styles.dayHeader}>T</div>
          <div className={styles.dayHeader}>W</div>
          <div className={styles.dayHeader}>T</div>
          <div className={styles.dayHeader}>F</div>
          <div className={styles.dayHeader}>S</div>

          {Array.from(new Array(firstDayOfYear)).map((_, index) => (
            <div key={`filler-${index}`} aria-hidden="true" />
          ))}
          {dates.map(({ dayOfMonth, dayOfYear, isToday, monthName }) => (
            <div
              key={dayOfYear}
              className={classnames(styles.dayContainer, {
                [styles.dayFirstOfMonth]: dayOfMonth === 1,
                [styles.today]: isToday,
              })}
            >
              <div className={styles.day}>
                {dayOfMonth === 1 ? (
                  <div className={styles.month}>{monthName}</div>
                ) : (
                  <div className={styles.dayOfMonth}>{dayOfMonth}</div>
                )}
                <div className={styles.events}>
                  {events[dayOfYear] &&
                    events[dayOfYear].map(
                      (event, index) =>
                        event && (
                          <div
                            key={index}
                            className={styles.eventContainer}
                            data-width={`${100 * event.days}%`}
                            style={{
                              width: `${100 * event.days}%`,
                              top: `calc(var(--event-height) * ${index})`,
                            }}
                          >
                            <div
                              className={styles.event}
                              title={`${
                                event.title
                              } (${differenceInCalendarDays(
                                event.endDate,
                                event.startDate,
                              )} days)`}
                            >
                              {event.title}
                            </div>
                          </div>
                        ),
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
