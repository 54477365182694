const CLIENT_ID =
  '828593554541-auj9mp9v8c5ac83bam1d0q4kbdl0q2oi.apps.googleusercontent.com';
const API_KEY = 'AIzaSyB8D99WVEHMX9xznzFyAvA9fihmpxCyhE8';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

const now = new Date();
let loaded = false;

const load = cb => {
  window.gapi.load('client:auth2', () => {
    loaded = true;
    cb();
  });
};

export const init = async cb => {
  if (!loaded) {
    load(() => init(cb));
  } else {
    await window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    });

    // console.log(window.gapi.client.calendar);
    window.gapi.auth2.getAuthInstance().isSignedIn.listen(cb);
    cb(window.gapi.auth2.getAuthInstance().isSignedIn.get());
  }
};

export const logIn = () => window.gapi.auth2.getAuthInstance().signIn();
export const logOut = () => window.gapi.auth2.getAuthInstance().signOut();

export const loadCalendars = () =>
  window.gapi.client.calendar.calendarList.list();

export const loadEvents = calendarId =>
  window.gapi.client.calendar.events.list({
    calendarId,
    timeMin: new Date(now.getFullYear(), 0, 1).toISOString(),
    timeMax: new Date(now.getFullYear() + 1, 0, 1).toISOString(),
    showDeleted: false,
    singleEvents: true,
    maxResults: 1000,
    orderBy: 'startTime',
  });
